<template>
  <v-card class="mt-4 ml-4 mr-4 mb-4">
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Documentation</v-toolbar-title>
    </v-toolbar>
    <v-tabs vertical>
      <v-tab> Présentation </v-tab>
      <v-tab> Installation </v-tab>
      <v-tab>
        <v-icon left> mdi-cog </v-icon>
        Configuration
      </v-tab>
      <v-tab>
        <v-icon left> mdi-folder </v-icon>
        Envoi vers dossier
      </v-tab>
      <v-tab>
        <v-icon left> mdi-email </v-icon>
        Envoi vers email
      </v-tab>
      <v-tab>
        <v-icon left> mdi-file-document-arrow-right </v-icon>
        Conversion d'un document
      </v-tab>
      <v-tab>
        <v-icon left> mdi-notebook </v-icon>
        Carnet d'adresses
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h2 class="mb-3">Présentation</h2>
            <p>
              L'application Easy Converter consiste en une application PC ou
              serveur qui va convertir au format Microsoft Office et/ou PDF des
              documents scannés depuis un MFP ou votre navigateur.
            </p>
            <div class="presentation">
              <v-img
                src="../assets/images/documentation/1.png"
                max-height="500"
                max-width="500"
                contain
              ></v-img>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h2 class="mb-3">Installation</h2>

            <v-alert dense border="left" type="warning">
              Pour l'installation d'Easy Converter rapprochez-vous de votre
              revendeur.
            </v-alert>

            <v-alert dense border="left" type="warning">
              Easy Converter nécessite l'utilisation du .NET Framework 4.7 et
              l'installera automatiquement. Ceci peut nécessiter le redémarrage
              du PC ou du serveur.
            </v-alert>

            <h4>1. Télécharger le fichier d'installation</h4>

            <h4>2. Lancer l'installation</h4>

            <h4>3. Renseigner l'identifiant d'agence</h4>
            <v-img
              src="../assets/images/documentation/install-id-agency.png"
              max-height="500"
              max-width="500"
              contain
            ></v-img>

            <v-alert dense outlined type="info">
              Vous trouverez votre id d'agence sur votre dashboard du
              <a href="https://admin.apptbc.com/" class="text-center"
                >portail admin TBC</a
              >
            </v-alert>

            <h4>4. Attendre la fin de l'installation</h4>
            <p>
              Une fois l'installation finie l'application Easy Converter va
              s'ouvrir pour être configurée. Une icône sera ajoutée sur le
              bureau et dans la barre d'état système.
            </p>
            <v-img
              src="../assets/images/documentation/install-systray.png"
              max-height="200"
              max-width="200"
              contain
            ></v-img>

            <h4>5. Ouvrir le port de connexion si un pare-feu est activé</h4>
            <p>
              L'application utilise le port 5000 pour communiquer avec les MFPs.
              Il faut configurer le pare-feu pour autoriser les communications
              TCP/UDP sur le port 5000. Il est possible de changer ce port dans
              la partie configuration.
            </p>

            <iframe
              src="https://www.youtube.com/embed/pQnCKqti9Ww?rel=0"
              frameborder="0"
              width="800"
              height="500"
              class="embed-responsive-item"
              allowfullscreen
            ></iframe>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h2 class="mb-3">Configuration</h2>

            <h4>Onglet Général</h4>
            <v-img
              src="../assets/images/documentation/config-general.png"
              max-height="600"
              max-width="600"
              contain
            ></v-img>

            <ul>
              <li>
                <strong>Répertoire d'entrée</strong>
                <br />
                C'est le répertoire par lequel vont transiter les images avant
                conversion. Vous devez entrer un chemin réseau valide.
              </li>
            </ul>

            <v-alert dense border="left" type="warning">
              Le dossier de scan est régulièrement nettoyé. Choisissez un
              dossier dédié sans autre données.
            </v-alert>

            <v-alert dense border="left" type="warning">
              Veuillez rentrer un login et mot de passe, ainsi qu'un domaine
              s'il y en a un, d'un utilisateur ayant les droits de
              lecture/écriture dans ce répertoire. Vous pourrez renseigner ces
              valeurs, en cliquant sur l'icône entourée en rouge dans l'image
              suivante.
            </v-alert>
            <v-img
              src="../assets/images/documentation/config-general-popup.png"
              max-height="600"
              max-width="600"
              contain
            ></v-img>

            <ul>
              <li>
                <strong>Répertoire de sortie</strong>
                <br />
                C'est le répertoire de destination des documents convertis.
              </li>
            </ul>

            <ul>
              <li>
                <strong>Destination email</strong>
                <br />
                Active ou désactive l'envoi par email des documents convertis.
              </li>
            </ul>

            <ul>
              <li>
                <strong>Destination dossier</strong>
                <br />
                Active ou désactive le stockage des documents convertis dans le
                répertoire de sortie
              </li>
            </ul>

            <v-alert dense outlined type="info">
              Dans l'application Easy Converter sur le MFP, vous pourrez choisir
              un des sous-répertoires du répertoire de sortie comme destination
              des documents convertis.
            </v-alert>

            <h4>Onglet Imprimante</h4>

            <v-alert dense border="left" type="warning">
              Pour ajouter un MFP, vous devez avoir configuré le SNMP V2 sur le
              MFP.
            </v-alert>

            <ul>
              <li>
                <strong>Ajout d'un MFP</strong>
                <br />
                Renseignez l'IP du MFP sur le réseau, le nom de l'utilisateur
                admin et le mot de passe puis cliquez sur enregistrer.
              </li>
            </ul>
            <v-img
              src="../assets/images/documentation/config-xerox1.png"
              max-height="600"
              max-width="600"
              contain
            ></v-img>

            <ul>
              <li>
                <strong>Modification d'un MFP</strong>
                <br />
                Sélectionnez le MFP dans la liste déroulante, modifiez les
                valeurs puis enregistrez.
              </li>
            </ul>
            <v-img
              src="../assets/images/documentation/config-xerox2.png"
              max-height="600"
              max-width="600"
              contain
            ></v-img>

            <h4>Onglet Licence</h4>

            <p>
              Sélectionnez un MFP dans la liste déroulante et retrouvez les
              informations de la licence en cours d'utilisation.
            </p>

            <p>
              Si celle-ci a expiré et qu'elle a été renouvellée par votre
              revendeur, cliquez sur "Rafraîchir" pour la récupérer.
            </p>
            <v-img
              src="../assets/images/documentation/config-licence.png"
              max-height="600"
              max-width="600"
              contain
            ></v-img>

            <h4>Onglet E-mail</h4>

            <p>
              Permet de configurer le SMTP et les valeurs par défaut de l'email.
              Vous pouvez envoyer un email de test.
            </p>
            <v-img
              src="../assets/images/documentation/config-email.png"
              max-height="600"
              max-width="600"
              contain
            ></v-img>

            <h4>Onglet Carnet d'adresses</h4>
            <p>
              Permet de configurer la prise en charge du LDAP dans le carnet
              d'adresses.
            </p>
            <v-img
              src="../assets/images/documentation/config-ldap.png"
              max-height="600"
              max-width="600"
              contain
              class="mb-2"
            ></v-img>

            <v-alert dense outlined type="info">
              Vous pouvez accéder au carnet d'adresses via l'url : http://{ip du
              pc}:{port du service}/addressbook (par ex.
              http://localhost:5000/addressbook)
            </v-alert>

            <h4>Onglet Avancés</h4>
            <p>
              Vous pouvez gérer l'affichage des différentes notifications. Le
              port du service peut-être modifié. Pensez dans ce cas à ouvrir le
              nouveau port dans votre pare-feu.
            </p>
            <!--  -->
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h2 class="mb-3">Envoi vers dossier</h2>
            <h4>
              1. Ouvrir l'application Easy Converter depuis l'accueil du MFP
            </h4>
            <h4>2. Choisir le format de sortie du document</h4>
            <v-img
              src="../assets/images/documentation/1.png"
              max-height="400"
              max-width="400"
              contain
            ></v-img>
            <h4>3. Choisir le sous-dossier de destination du document</h4>
            <v-alert dense border="left" type="warning">
              Si votre répertoire de sortie ne contient pas de sous-dossiers,
              cette étape ne sera pas présentée.
            </v-alert>
            <v-img
              src="../assets/images/documentation/2.png"
              max-height="400"
              max-width="400"
              contain
            ></v-img>
            <h4>4. Choisir les options de numérisation et numériser</h4>
            <v-img
              src="../assets/images/documentation/5.png"
              max-height="400"
              max-width="400"
              contain
            ></v-img>
            <h4>
              5. Récupérer vos documents convertis dans le dossier ou
              sous-dossier de destination
            </h4>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h2 class="mb-3">Envoi vers email</h2>
            <h4>
              1. Ouvrir l'application Easy Converter depuis l'accueil du MFP
            </h4>
            <h4>2. Choisir le format de sortie du document</h4>
            <v-img
              src="../assets/images/documentation/1.png"
              max-height="400"
              max-width="400"
              contain
            ></v-img>
            <h4>
              3. Choisir l'envoi par email en cliquant sur l'enveloppe en haut à
              droite
            </h4>
            <v-img
              src="../assets/images/documentation/2.png"
              max-height="400"
              max-width="400"
              contain
            ></v-img>
            <h4>4. Saisissez vos destinataires, un objet et un message</h4>
            <v-img
              src="../assets/images/documentation/3.png"
              max-height="400"
              max-width="400"
              contain
            ></v-img>
            <h4>
              5. Vous pouvez utiliser le répertoire pour sélectionner des
              destinataires.
            </h4>
            <v-img
              src="../assets/images/documentation/4.png"
              max-height="400"
              max-width="400"
              contain
            ></v-img>
            <h4>6. Choisir les options de numérisation et numériser</h4>
            <v-img
              src="../assets/images/documentation/5.png"
              max-height="400"
              max-width="400"
              contain
            ></v-img>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h2 class="mb-3">Utilisation depuis le PC</h2>
            <h4>
              Ouvrez votre navigateur à l'adresse http://{ip du pc}:{port du
              service} (par ex. http://localhost:5000)
            </h4>
            <h5>1. Choisissez votre format de conversion</h5>
            <h5>2. Sélectionnez vos documents</h5>
            <h5>3. Choisissez la langue de conversion</h5>
            <h5>4. Cliquez sur Convertir</h5>
            <h5>5. Téléchargez vos fichiers convertis</h5>
            <v-img
              src="../assets/images/documentation/upload.png"
              max-height="600"
              max-width="600"
              contain
            ></v-img>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h2 class="mb-3">Carnet d'adresses</h2>
            <h4>Accès</h4>
            <h5>
              1. Ouvrez votre navigateur à l'adresse http://{ip du pc}:{port du
              service} (par ex. http://localhost:5000)
            </h5>

            <h5 class="mb-1">
              2. Cliquez sur le bouton du carnet d'adresses
              <v-img
                src="../assets/images/documentation/addressbook-btn.png"
                max-height="50"
                max-width="50"
                contain
              ></v-img>
            </h5>

            <v-alert dense outlined type="info">
              Vous pouvez accéder au carnet d'adresses via l'url : http://{ip du
              pc}:{port du service}/addressbook (par ex.
              http://localhost:5000/addressbook)
            </v-alert>

            <h4>Utilisation</h4>
            <h5>1. Importez votre carnet d'adresses</h5>
            <h5>2. Ajoutez un destinataire</h5>
            <h5>3. Modifiez ou supprimez un destinataire</h5>
            <v-alert dense border="left" type="warning">
              L'importation nécessite un fichier csv et la présence des champs
              suivants : 'name' et 'email'.
            </v-alert>

            <v-img
              src="../assets/images/documentation/addressbook.png"
              max-height="700"
              max-width="700"
              contain
              class="mb-2"
            ></v-img>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  name: "DocumentationView",
  components: {},
  data() {
    return {
      items: [{}]
    };
  }
};
</script>

<style>
.v-tabs--vertical > .v-tabs-bar .v-tabs-bar__content {
  align-items: self-start !important;
}

.presentation {
  display: flex;
  justify-content: center;
}
</style>
