<template>
  <div>
    <v-container fluid>
      <v-row justify="space-around" class="background-color-mfp">
        <v-col cols="4" class="background-color-mfp pl-0 pb-0">
          <v-img
            src="../assets/images/mfp.png"
            max-height="500"
            max-width="500"
            contain
          ></v-img>
        </v-col>

        <v-col cols="8" class="background-color-mfp pl-0" align-self="center">
          <h1>Easy Converter</h1>
          <h2 class="color-text">
            Convertissez facilement vos originaux papiers et électroniques en
            documents Office ou PDF.
          </h2>
        </v-col>
        <!-- <v-col cols="4">
          <v-img
            max-height="150"
            max-width="200"
            class="img-easyconverter"
            src="../assets/images/easyconverter.png"
            contain
          ></v-img>
        </v-col> -->
      </v-row>
    </v-container>
    <!-- <v-card elevation="10">
      <v-card-title>
        <h1>Easy Converter</h1>
      </v-card-title>
      <v-card-text>
        <h4>
          Convertissez facilement vos originaux papiers et électroniques en
          documents Office ou PDF.
        </h4>
      </v-card-text>
    </v-card> -->
    <v-container class="mt-3 mb-3 second-container pt-0" fluid>
      <v-row justify="space-around">
        <v-col cols="5">
          <v-icon size="60" color="#42A5F5" class="circle-icon">
            mdi-note-text-outline
          </v-icon>
          <h2>Conversion des originaux papiers</h2>
          <h4 class="color-text">
            Convertissez facilement en quelques secondes vos originaux papiers
            en documents Word, Excel, Powerpoint ou PDF.
          </h4>
          <h4 class="color-text">
            Avec Easy Converter la conversion est simple, rapide et se fait en
            local. Que vous exerciez dans le domaine du légal, du médical ou
            d’autres secteurs, les données restent chez vous.
          </h4>
          <h4 class="color-text mb-2">
            Les documents peuvent être envoyés dans un dossier ou une adresse
            e-mail.
          </h4>
        </v-col>
        <v-col cols="5">
          <v-icon size="60" color="#42A5F5" class="circle-icon">
            mdi-file-import-outline
          </v-icon>
          <h2>Conversion des documents électroniques</h2>
          <h4 class="color-text">
            Convertissez facilement en quelques secondes vos originaux
            électroniques en documents Word, Excel, Powerpoint ou PDF.
          </h4>
          <h4 class="color-text mb-2">
            Avec Easy Converter vous pouvez combiner plusieurs documents de type
            PDF, TIFF, JPEG, PNG. Il est également possible de générer un PDF
            protégé et / ou Océrisé ou encore un PDA/A.
          </h4>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col cols="5">
          <!-- <v-img src="../assets/images/documentation/1.png" contain></v-img> -->
          <v-carousel cycle height="400" hide-delimiters show-arrows-on-hover>
            <v-carousel-item
              class=""
              src="../assets/images/documentation/1.png"
            ></v-carousel-item>
            <v-carousel-item
              src="../assets/images/documentation/2.png"
            ></v-carousel-item>
            <v-carousel-item
              src="../assets/images/documentation/3.png"
            ></v-carousel-item>
          </v-carousel>
        </v-col>

        <v-col cols="5">
          <v-carousel cycle height="400" hide-delimiters show-arrows-on-hover>
            <v-carousel-item
              src="../assets/images/caroussel/01.png"
            ></v-carousel-item>
            <v-carousel-item
              src="../assets/images/caroussel/02.png"
            ></v-carousel-item>
            <v-carousel-item
              src="../assets/images/caroussel/03.png"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row justify="space-around">
        <v-col cols="4">
          <v-alert border="top" colored-border type="info" elevation="4">
            <h2>Compatibilité</h2>
            <h4 class="mb-1">
              <v-icon size="20" color="#42A5F5" class="circle-icon mr-1">
                mdi-printer </v-icon
              >WF-C20600/C20750/C21000/M21000
            </h4>
            <h4 class="mb-1">
              <v-icon size="20" color="#42A5F5" class="circle-icon mr-1">
                mdi-printer </v-icon
              >AM-C4000/C5000/C6000
            </h4>
            <h4 class="mb-1">
              <v-icon size="20" color="#42A5F5" class="circle-icon mr-1">
                mdi-printer </v-icon
              >WF-C878R/C879R
            </h4>
            <h4 class="mb-1">
              <v-icon size="20" color="#42A5F5" class="circle-icon mr-1">
                mdi-printer </v-icon
              >WF-C579R
            </h4>
            <h4>
              <v-icon size="20" color="#42A5F5" class="circle-icon mr-1">
                mdi-microsoft-windows
              </v-icon>
              >= 10
            </h4>
          </v-alert>
          <!-- <v-card elevation="10" class="mt-4 ml-4 mb-4" max-width="500">
            <v-card-title>Compatibilité</v-card-title>
            <v-card-text>
              <h4>C4000/5000/6000</h4>
              <h4>C579R</h4>
            </v-card-text>
          </v-card> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {};
  }
};
</script>

<style>
.circle-icon {
  background: hsl(209, 100%, 88%);
  padding: 10px;
  border-radius: 50%;
}

.color-text {
  color: #90a4ae;
}
.img-easyconverter {
  filter: brightness(120%);
  filter: contrast(120%);
  filter: drop-shadow(10px 10px 10px rgb(0, 0, 0));
}
.top {
  left: 100px;
  top: 100px;
  z-index: 1;
}

.second-container {
  background-color: #eceff1;
}

.background-mfp {
  background-image: url("../assets/images/mfp.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 400px;
  height: 450px;
}

.background-color-mfp {
  background-color: #dadde2;
}
.v-image__image--cover {
  background-size: contain !important;
}
</style>
