<template>
  <v-app>
    <v-app-bar app color="#ECEFF1" height="100">
      <v-row justify="space-between">
        <v-col cols="3">
          <v-img
            src="./assets/images/easyconverter.png"
            max-height="60"
            max-width="132"
            contain
          ></v-img>
        </v-col>
        <v-col cols="4">
          <v-tabs fixed-tabs background-color="#ECEFF1">
            <v-tab @click="goTo('home')">Accueil</v-tab>
            <!-- <v-tab @click="goTo('video')">Vidéo</v-tab> -->
            <v-tab @click="goTo('documentation')">Documentation</v-tab>
            <v-tab
              href="https://www.mfpsolution.com/EasyConverter_setup.exe"
              target="_self"
            >
              Télécharger
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="3"> </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer dark padless style="display: block">
      <v-card flat tile color="#ECEFF1" class="black--text text-center">
        <v-card-text class="black--text pt-0">
          <v-row justify="space-around">
            <v-col cols="3" class="text-left">
              TBC
              <br />
              215 Avenue François Verdier
              <br />
              81 000 ALBI
              <br />
              FRANCE
              <br />
            </v-col>
            <v-col cols="4" class="text-center to-display">
              <v-img
                src="./assets/images/logo-tbc-solutions.svg"
                max-height="180"
                max-width="240"
                contain
              ></v-img>
            </v-col>
            <v-col cols="3">
              <v-btn
                v-for="(icon, index) in icons"
                :key="index"
                class="mx-4 black--text"
                :href="icon.link"
                target="_blank"
                icon
              >
                <v-icon size="40px">
                  {{ icon.icon }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="space-around">
            <!-- <a href="/app/easyconverter/support/" class="">Support</a> -->
            <a href="https://www.solutions-tbc.fr/" class="text-center">
              www.solutions-tbc.fr
            </a>
            <a
              href="https://www.solutions-tbc.fr/mentions-legales/"
              class="text-center"
            >
              Mentions Légales
            </a>
            <a
              href="https://www.solutions-tbc.fr/politique-de-confidentialite/"
              class="text-center"
            >
              Politique de confidentialité
            </a>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="black--text">
          {{ new Date().getFullYear() }} — <strong>TBC</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      icons: [
        {
          icon: "mdi-linkedin",
          link: "https://www.linkedin.com/company/solutionstbc/"
        },
        {
          icon: "mdi-youtube",
          link: "https://www.youtube.com/channel/UCkxUhK8BBLeIhbLb57RoiSw"
        }
      ]
    };
  },
  methods: {
    goTo(name) {
      return this.$router.push({ name: name }).catch(() => {});
    }
  }
};
</script>

<style>
.to-display {
  display: contents;
}
</style>
